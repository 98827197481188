import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const SideMenu = () => {
  const [showElement, setShowElement] = useState(0)

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const wY = window.scrollY
    const bH = document.body.scrollHeight

    if (wY > bH / 2) {
      setShowElement(1)
    } else {
      setShowElement(0)
    }
  }

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className={`dhsv_scrollup${showElement ? ' fade-in ' : ''}`}>
      <button onClick={scrollUp}>
        <i className="material-icons">arrow_upward</i>
      </button>
    </div>
  )
}

SideMenu.propTypes = {
  isMD: PropTypes.bool,
}

export default SideMenu
